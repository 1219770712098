import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { InformationBox, InfoboxColumn, InfoboxSection, list } from './information-box';
import { isValidAustPhoneNumber } from '../../functions/common.js';
import Link from '../elements/link';

export const InfoboxTour = (query) => {
  const regions = useMemo(() => list(query.relationships.field_region, 'title', true), []),
    phoneNumber = query.field_phone || null,
    phoneNumberCleaned = (phoneNumber && phoneNumber.replaceAll(' ', '')) || null;

  return (
    <InformationBox>
      {/* Column 1 */}
      <InfoboxColumn>
        {regions.length > 0 && (
          <InfoboxSection sectionTitle="Regions">
            <ul>{regions}</ul>
          </InfoboxSection>
        )}
      </InfoboxColumn>

      {/* Column 2 */}
      <InfoboxColumn>
        {(query.field_phone || query.field_email || query.field_contact?.processed) && (
          <InfoboxSection sectionTitle="Contact">
            <ul>
              {isValidAustPhoneNumber(phoneNumberCleaned) && (
                <li className="pb-xsmall" key={phoneNumberCleaned}>
                  <Link title="Call this number" to={`tel:${phoneNumberCleaned}`}>
                    {query.field_phone}
                  </Link>
                </li>
              )}
              {query.field_email && (
                <li className="pb-xsmall" key={query.field_email}>
                  <Link
                    title={`Send an email to ${query.field_email}`}
                    to={`mailto:${query.field_email}`}
                  >
                    Send an email
                  </Link>
                </li>
              )}
            </ul>
            {query.field_contact?.processed && (
              <div dangerouslySetInnerHTML={{ __html: query.field_contact?.processed }} />
            )}
          </InfoboxSection>
        )}
        {query.field_website?.uri && (
          <InfoboxSection sectionTitle="Website">
            <Link to={query.field_website?.uri}>
              {query.field_website?.title || query.field_website?.uri}
            </Link>
          </InfoboxSection>
        )}
        {query.field_booking_url?.uri && (
          <InfoboxSection sectionTitle="Book online">
            <Link to={query.field_booking_url?.uri}>
              {query.field_booking_url?.title || `Click here to book`}
            </Link>
          </InfoboxSection>
        )}
      </InfoboxColumn>

      {/* Column 3 */}
      <InfoboxColumn>
        {query.field_departs_from && (
          <InfoboxSection sectionTitle="Departs from">
            <p>{query.field_departs_from}</p>
          </InfoboxSection>
        )}
        {query.field_departs_from_frequency && (
          <InfoboxSection sectionTitle="How often?">
            <p>{query.field_departs_from_frequency}</p>
          </InfoboxSection>
        )}
        {query.field_max_group_size && (
          <InfoboxSection sectionTitle="Maximum group size">
            <p>{query.field_max_group_size} people</p>
          </InfoboxSection>
        )}
      </InfoboxColumn>

      {/* Column 4 */}
      <InfoboxColumn>
        {query.field_awards_and_qualifications && (
          <InfoboxSection sectionTitle="Awards and quailifications">
            <div dangerouslySetInnerHTML={{ __html: query.field_awards_and_qualifications }} />
          </InfoboxSection>
        )}
      </InfoboxColumn>
    </InformationBox>
  );
};

export const query = graphql`
  fragment InformationBoxTour on paragraph__information_tour {
    id
    drupal_id
    internal {
      type
    }
    field_awards_and_qualifications
    field_booking_url {
      title
      uri
    }
    field_contact {
      processed
    }
    field_departs_from
    field_departs_from_frequency
    field_email
    field_max_group_size
    field_phone
    field_website {
      title
      uri
    }
    paragraph_type {
      drupal_internal__target_id
    }
    relationships {
      field_region {
        title
        path {
          alias
        }
      }
    }
  }
`;
